<template>
  <!-- 中标数据报告 -->
  <div style="margin-top: 80px;min-height: 500px">
    <el-row>
      <el-col :span="24">
        <div class="container">
          <div class="condition-content">
            <div class="dis-bet-cen condition-title">
              <span class="screening-conditions-title">共查询到 <span class="font-color-red">{{page.total}}</span> 条行业分析报告</span>
            </div>
            <div>
              <v-for v-for="(item, index) in achievementList" :key="index">
                <el-row :gutter="10" class="dis-sta-cen list-hover" style="padding: 20px" @click="handleToPdf(item.file)">
                  <el-col :span="4">
                    <el-image class="tableImage"
                              :src="item.cover"
                              @error="handleError($event)"
                              fit="contain">
                    </el-image>
                  </el-col>
                  <el-col :span="18">
                    <el-row style="text-align: left;">
                      <el-col :span="24">
                        <div class="divOmit" style="font-size: 16px;color: #333333;">{{ item.title }}</div>
                      </el-col>
                      <el-col :span="24">
                        <div v-if="item.reportType === '0'" class="divOmit" style="font-size: 12px;color: #999999;">类型：平台信用</div>
                        <div v-if="item.reportType === '1'" class="divOmit" style="font-size: 12px;color: #999999;">类型：月度活跃排名</div>
                      </el-col>
                      <el-col :span="24">
                        <div class="divOmit" style="font-size: 14px;color: #666666;">{{ item.describe }}</div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
                 <el-divider v-if="(index + 1) != achievementList.length"></el-divider>
              </v-for>
            </div>
           </div>
          <!--分页组件-->
          <div class="dis-end-sta" style="margin: 30px 0">
            <el-pagination
              :hide-on-single-page="true"
              :pager-count="page.pagerCount"
              background
              layout="prev, pager, next"
              @current-change="currentChange"
              :total="page.total">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getActiveRankingList } from '@/api/forward.js'
export default {
  name: 'index-achievement-list',
  data () {
    return {
      achievementList: [],
      page: { // 分页使用
        total: 0, // 总条数
        pagerCount: 7,
        pageNum: 1
      },
      value: [],
      options: []
    }
  },
  mounted () {
    document.οncοntextmenu = function (e) {
      return false
    }
    this.initAchievement()
  },
  methods: {
    handleChangeKk (value) {
      console.log(value)
    },
    handleToPdf: function (e) {
      // window.open(e + '#toolbar=0')
      window.open(e, '', 'toolbar=no,location=no,directories=no,menubar=no,scrollbars=no,resizable=yes,status=no,top=0,left=0')
    },
    // 翻页时触发
    currentChange (cuttentPage) {
      const vm = this
      document.documentElement.scrollTop = 0
      vm.page.pageNum = cuttentPage
      vm.initAchievement()
    },
    initAchievement () {
      const vm = this
      const data = {
        // dateBackgroundUrl: '/activeRanking/getActiveRankingList',
        // dateBackgroundRequestType: 'get',
        // data: {
        //   reportType: '1',
        //   pageNum: vm.page.pageNum,
        //   pageSize: 10
        // },
        reportType: '1',
        pageNum: vm.page.pageNum,
        pageSize: 10
      }
      getActiveRankingList(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.achievementList = res.list
          vm.page.total = res.total
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.divOmit {
  margin: 10px 0;
}
.list-hover:hover{
  background-color: #F3F9FD;
  cursor: pointer;
}
.el-divider--horizontal {
  margin: 0 !important;
}
</style>
